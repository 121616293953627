import React, {useState} from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {Formik} from "formik";
import {LoginSchemaValidation} from "config/form/FormValidation/Auth";
import {setAuthenticatedUser} from "store/actions/auth";
import {setSelectedHospital} from "store/actions/hospital";
import {useLogin} from "hooks/Auth";
import {normalizeUsername} from "utils/string";
import {SubmitButton} from "components/Button";
import {loadLocalAssets} from "utils/loader";
import {setAuthHeader} from "services/axios";

function MappingErrorMessage(errorMessage) {
	switch (errorMessage) {
		case "Invalid username or password":
			return "Email/nomor HP atau kata sandi yang Anda masukkan tidak sesuai";

		default:
			return errorMessage;
	}
}

export function LoginPage() {
	const dispatch = useDispatch();
	const history = useHistory();
	const formikRef = React.useRef(null);
	const [persistLogin, setPersistLogin] = useState(false);
	const [showPassword, setShowPassword] = React.useState(false);
	const {mutate, isLoading, isError, error} = useLogin();

	const handleFormSubmit = formData => {
		mutate(
			{role: "vendor", ...formData, username: normalizeUsername(formData.username.toLowerCase())},
			{
				onSuccess: res => {
					const {token, ...basicProfile} = res?.data;
					const getAllRolesId = basicProfile?.staff?.rsMany?.map(item => item?.roleId);
					const data = {
						accessToken: token,
						accessExpired: persistLogin ? dayjs().add(15, "day") : dayjs().add(5, "minute"),
						isPersisted: persistLogin,
						profile: basicProfile,
						isLoggedIn: true,
						userRoles: [...new Set(getAllRolesId)] || [],
						roleId: getAllRolesId?.[0] || "0",
					};

					setAuthHeader(token);
					dispatch(setAuthenticatedUser(data));
					dispatch(setSelectedHospital(basicProfile?.staff?.rsMany?.[0] || null));
					history.push("/");
				},
			},
		);
	};

	return (
		<div class="login-wrapper">
			<div class="form-main">
				<Formik
					initialValues={{username: "", password: ""}}
					innerRef={formikRef}
					validationSchema={LoginSchemaValidation}
					onSubmit={handleFormSubmit}>
					{({handleSubmit, handleBlur, handleChange, values, touched, errors}) => (
						<form
							class="inner-fm"
							onKeyDown={e => {
								if (e.key === "Enter") {
									handleSubmit();
								}
							}}
							onSubmit={handleSubmit}>
							<img alt="logo" class="img-fluid mb-5" src={loadLocalAssets("img/logo.svg")} />
							<div class="ttl-28 mb-4">Masuk</div>
							<div class="form-login">
								<div class="label-fl mb-2">Email atau Nomor HP</div>
								<input
									class={clsx(
										"form-control",
										errors.username && touched.username && "form-control-error",
									)}
									disabled={isLoading}
									name="username"
									type="text"
									value={values?.username}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								{errors.username && <p className="form-error-item-message">{errors.username}</p>}
							</div>
							<div class="form-login">
								<div class="label-fl mb-2">Kata sandi</div>
								<div class="position-relative">
									<input
										class={clsx(
											"form-control",
											errors.password && touched.password && "form-control-error",
										)}
										disabled={isLoading}
										id="password-1"
										name="password"
										type={showPassword ? "text" : "password"}
										value={values?.password}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									<div class="for-btn-show">
										<button
											class="btn btn-show"
											toggle="#password-1"
											type="button"
											onClick={() => setShowPassword(!showPassword)}
										/>
									</div>
									{errors.password && touched.password && (
										<p className="form-error-item-message">{errors.password}</p>
									)}
								</div>
							</div>

							<a
								class="text-600 d-block mb-3"
								style={{cursor: "pointer"}}
								onClick={() => history.push("/auth/forgot-password")}>
								Lupa Kata Sandi?
							</a>

							<div class="ingatkan mb-4">
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										value={persistLogin}
										onChange={e => setPersistLogin(e.target.checked)}
									/>
									<label class="text-green text-normal roboto" for="check-1">
										Ingat Saya
									</label>
								</div>
							</div>

							<SubmitButton
								className="btn btn-orange w-100"
								disabled={!LoginSchemaValidation.isValidSync(values) || isLoading}
								loading={isLoading}
								text="Masuk"
								type="submit"
							/>
							{isError && (
								<p className="form-error-item-message">
									{MappingErrorMessage(error?.response?.data?.message)}
								</p>
							)}

							<div className="text-center mt-3">
								<a
									className="text-link cursor-pointer"
									href="/layanan-obat/submit"
									style={{textDecoration: "underline"}}
									target="_blank">
									Gunakan form berikut untuk membuat akun
								</a>
							</div>
						</form>
					)}
				</Formik>
			</div>
			<div class="img-main" style={{backgroundImage: `url(${loadLocalAssets("img/bg-login.jpg")})`}} />
		</div>
	);
}
