import {DashboardLayout} from "components/Layout";
import * as Yup from "yup";
import {Col, Row} from "react-bootstrap";
import {Formik} from "formik";
import {useRef} from "react";
import clsx from "clsx";
import PhoneInput from "react-phone-number-input";
import {useSubmitLayananObat} from "hooks/LayananObat/useMutationLayananObat";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";
import {useHistory} from "react-router-dom";

import {NoAuthLayananInformasiObat} from "./components/NoAuthInformasiObatForm";

const FormSchemaValidation = Yup.object().shape({
	vendorName: Yup.string().required("Nama Vendor wajib di isi"),
	phoneNumber: Yup.string().required("Nomor Telepon wajib di isi"),
	email: Yup.string().email("Email tidak valid").required("Email wajib di isi"),
	hospitalId: Yup.string().required("Rumah Sakit wajib di isi"),
	divisionId: Yup.string().required("Divisi wajib di isi"),
	categoryId: Yup.string().required("Kategori Obat wajib di isi"),
	medicineId: Yup.string().required("Nama Obat wajib di isi"),
	details: Yup.array().of(
		Yup.object().shape({
			unitId: Yup.string(),
			sediaanId: Yup.string(),
			qty: Yup.number(),
			price: Yup.number(),
		}),
	),
	description: Yup.string(),
});

const FormInitialValues = {
	vendorName: "",
	phoneNumber: "",
	email: "",
	hospitalId: "",
	description: "",
	divisionId: "",
	categoryId: "",
	medicineId: "",
	details: [],
};

const LayananObatSubmit = () => {
	const formikRef = useRef();
	const {showToast} = useToast();
	const history = useHistory();
	const {mutate} = useSubmitLayananObat();

	const handleSubmit = data => {
		mutate(data, {
			onSuccess: () => {
				showToast("success", "Data berhasil ditambahkan", 3000);
				history.push("/layanan-obat");
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
			},
		});
	};

	return (
		<DashboardLayout>
			<div class="box-white setting-content h-100 no-border p24">
				<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
					<div class="ttl-20">Tambah Layanan Obat Baru</div>
				</div>

				<hr />
				<Formik
					initialValues={FormInitialValues}
					innerRef={formikRef}
					validationSchema={FormSchemaValidation}
					onSubmit={handleSubmit}>
					{({handleSubmit, values, errors, touched, setFieldValue}) => (
						<>
							<div class="text-bold text-black text-decoration-underline mb-4">Informasi Vendor</div>
							<Row className="gx-2 mb-3">
								<Col xs={6}>
									<div className="setting-item">
										<label class="setting-label required">Nama Vendor</label>
										<input
											className={clsx("form-control", errors?.vendorName && "form-control-error")}
											name="vendorName"
											value={values?.vendorName}
											onChange={e => {
												setFieldValue("vendorName", e.target.value);
											}}
										/>
										{errors?.vendorName && touched?.vendorName && (
											<p className="form-error-item-message">{errors.vendorName}</p>
										)}
									</div>
								</Col>
							</Row>
							<Row className="gx-2 mb-3">
								<Col xs={6}>
									<div className="setting-item">
										<label class="setting-label required">No Telepon</label>
										<PhoneInput
											className={clsx(
												touched?.phoneNumber && errors?.phoneNumber && "form-control-error",
											)}
											countryCallingCodeEditable={false}
											defaultCountry="ID"
											international={true}
											name="phoneNumber"
											placeholder="Nomor Telepon"
											value={`+${values.phoneNumber}`}
											onChange={val => {
												setFieldValue("phoneNumber", val?.replace(/\D/g, ""));
											}}
										/>
										{errors?.phoneNumber && touched?.phoneNumber && (
											<p className="form-error-item-message">{errors.phoneNumber}</p>
										)}
									</div>
								</Col>
							</Row>
							<Row className="gx-2 mb-3">
								<Col xs={6}>
									<div className="setting-item">
										<label class="setting-label required">Email</label>
										<input
											className={clsx("form-control", errors?.email && "form-control-error")}
											name="email"
											value={values?.email}
											onChange={e => {
												setFieldValue("email", e.target.value);
											}}
										/>
										{errors?.email && touched?.email && (
											<p className="form-error-item-message">{errors?.email}</p>
										)}
									</div>
								</Col>
							</Row>
							{values && FormSchemaValidation ? (
								<NoAuthLayananInformasiObat
									FormSchemaValidation={FormSchemaValidation}
									errors={errors}
									handleSubmit={handleSubmit}
									innerRef={formikRef}
									setFieldValue={setFieldValue}
									touched={touched}
									values={values}
								/>
							) : null}
						</>
					)}
				</Formik>
			</div>
		</DashboardLayout>
	);
};

export {LayananObatSubmit};
