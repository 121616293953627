import clsx from "clsx";
import {isMobile} from "react-device-detect";
import {useState} from "react";
import {useSelector} from "react-redux";
import {loadLocalAssets} from "utils/loader";
import {Toast} from "components/Toast";
import {ModalProfile} from "components/Modal";

import {Sidemenu} from "./../Navbar";

export function DashboardLayout({children}) {
	const {roleId: selectedRoleId, profile, isLoggedIn} = useSelector(state => state.AuthReducer);

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isSettingOpen, setIsSettingOpen] = useState(false);
	const [isModalProfileOpen, setIsModalProfileOpen] = useState(false);

	return (
		<div
			className={clsx(
				"main-wrapper",
				isMobile && "closed",
				!isMobile && isMenuOpen && "closed",
				isMobile && isMenuOpen && "show",
			)}>
			<ModalProfile visible={isModalProfileOpen} onClose={() => setIsModalProfileOpen(false)} />
			<div className="sidebar">
				<div className="nav-box">
					<div className="logo">
						<div className="for-logo">
							<a href="/">
								<img alt="Logo" className="img-fluid" src={loadLocalAssets("img/logo.svg")} />
							</a>
						</div>
						<div className="for-btn">
							<button className="btn btn-menu" onClick={() => setIsMenuOpen(!isMenuOpen)} />
						</div>
					</div>

					<div className="avatar-row">
						<a className="avatar" href="setting-staff.html">
							<img
								alt="avatar"
								className="img-fluid"
								src={profile?.profilePhoto || loadLocalAssets("img/default_avatar.png")}
							/>
						</a>
					</div>

					<div className="for-btm">
						<button className="btn btn-close-mobile p-1" onClick={() => setIsMenuOpen(false)}>
							<span className="icon-ico-close" />
						</button>
					</div>

					<Sidemenu role={selectedRoleId} />
				</div>
			</div>

			{/* Page Content */}

			<div className="page-content">
				<header className="header">
					<div className="container-fluid">
						<div className="header-inner">
							<div className="header-left">
								<button className="btn btn-toggle p-1" onClick={() => setIsMenuOpen(true)}>
									<span className="icon-ico-menu-mobile text-orange" />
								</button>
							</div>
							<div className="header-center">
								{isLoggedIn ? (
									<>
										<div className="logo-for-m">
											<a href={null}>
												<img className="img-fluid" src={loadLocalAssets("img/logo.svg")} />
											</a>
										</div>
										<div className="selectric">
											<span className="label">Vendor</span>
											<b className="button" />
										</div>
									</>
								) : null}
							</div>
							<div className="header-right">
								<div className="d-flex align-items-center">
									{isLoggedIn ? (
										<div className="for-notif">
											<button className="btn btn-notif">
												<span className="icon-ico-notif" />
											</button>

											{/* <div className="notif-status">39</div> */}
										</div>
									) : null}
									<div
										className={clsx(
											"selectric-wrapper selectric-select-top selectric-below",
											isSettingOpen && "selectric-open",
										)}>
										<div
											className=""
											onClick={() => (isLoggedIn ? setIsSettingOpen(!isSettingOpen) : null)}>
											<a className="btn btn-profile" role="button">
												<img
													alt="Avatar"
													className="img-fluid"
													src={
														profile?.profilePhoto ||
														loadLocalAssets("img/default_avatar.png")
													}
												/>
											</a>
										</div>
										<div className="selectric-items" style={{width: 200, left: -120}}>
											<div className="selectric-scroll">
												<ul>
													<li />
													<li onClick={() => setIsModalProfileOpen(true)}>Profile</li>
													{/* <li>Ubah Kata Sandi</li> */}
												</ul>
											</div>
										</div>
										<input className="selectric-input" tabIndex="0" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>

				<Toast />
				<div className="main-content">{children}</div>
			</div>
		</div>
	);
}
