import {useMemo, useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

function useNoAuthSediaanOptions() {
	const result = useQuery(["sediaanOptions"], async () => await axios.get(`/sediaan/list`).then(res => res.data));

	return result;
}

function useNoAuthMedicineUnitOptions() {
	const result = useQuery(
		["medicineUnitOptions"],
		async () => await axios.get(`/medicine/unit/list`).then(res => res.data),
	);

	return result;
}

function useNoAuthHospitals(enabled = true, limit = 10) {
	const [filter, filterHospitals] = useState({
		page: 1,
		search: null,
		limit,
		sortBy: "id.desc",
	});
	const [hospitalId, hospitalById] = useState(null);
	const fallback = [];
	const {
		data: hospitals = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(
		["hospitals", hospitalId, filter],
		async () => await axios.get(`/hospital/all`, {params: {...filter, sortBy: "id.desc"}}),
		{enabled},
	);

	return {hospitals, isLoading, isError, error, filter, filterHospitals, hospitalById};
}

function useNoAuthHospitalsOptions(enabled) {
	const {hospitals, isLoading} = useNoAuthHospitals(enabled);
	const hospitalOptions = useMemo(() => {
		if (hospitals?.data?.success) {
			return hospitals?.data?.data?.map(hospital => ({
				value: hospital?.id + "",
				label: hospital?.name,
				desc: hospital?.address,
				image: hospital?.logo,
			}));
		}

		return [];
	}, [hospitals]);

	return {hospitalOptions, isLoading};
}

async function getNoAuthMedicines(hospitalId, categoryId) {
	const {data} = await axios.get(`/v2/medicine/by_hospital_all/${hospitalId}/${categoryId}`);

	return data;
}

function useNoAuthListAvailableMedicine(hospitalId, categoryId) {
	const [filter, filterMedicines] = useState({
		page: 1,
		search: null,
		limit: 10,
		hospitalId,
	});

	const [medicineId, medicineById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(
		["medicines", {...filter}, medicineId, categoryId],
		async () => getNoAuthMedicines(hospitalId, categoryId),
		{
			enabled: !!hospitalId && !!categoryId,
		},
	);

	return {data, isLoading, isError, error, filter, filterMedicines, medicineById};
}

function useNoAuthDetailMedicine(medicineId, enabled = true) {
	return useQuery({
		queryKey: medicineId && ["medicine", medicineId],
		queryFn: () => axios.get(`/v2/medicine/${medicineId}/vendor`).then(res => res.data),
		refetchOnMount: true,
		refetchOnWindowFocus: false,
		enabled: enabled && !!medicineId,
	});
}

function useNoAuthListDivisionById(filter, enabled) {
	return useQuery({
		queryKey: ["rs_division", filter],
		queryFn: () => axios.get(`/division/by_hospital_all/${filter.hospitalId}`).then(res => res.data),
		refetchOnMount: false,
		enabled: enabled && !!Object.keys(filter).length && !!filter.hospitalId,
	});
}

function useNoAuthMedicineCategoryOptions({hospitalId}, enabled = true) {
	const result = useQuery(
		["medicineCategoryOptionsHospital", hospitalId],
		async () => await axios.get(`/medicine/category/by_hospital_all/${hospitalId}`).then(res => res.data),
		{
			enabled: enabled && !!hospitalId,
		},
	);

	return result;
}

export {
	useNoAuthSediaanOptions,
	useNoAuthMedicineUnitOptions,
	useNoAuthHospitalsOptions,
	useNoAuthListAvailableMedicine,
	useNoAuthListDivisionById,
	useNoAuthMedicineCategoryOptions,
	useNoAuthDetailMedicine,
};
