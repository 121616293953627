import clsx from "clsx";
import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {logout} from "store/actions/auth";
import {Link, useLocation} from "react-router-dom";
import {ModalConfirmation} from "components/Modal";
import {getAppsMenu} from "config/menu";
import {checkCurrentPath} from "utils/string";
import {useLayoutContext} from "contexts/LayoutContextProvider";

const menuWithSubmenuStyles = {
	background: "transparent",
	border: 0,
	outline: 0,
};

export function Sidemenu() {
	const [showLogoutModal, setShowLogoutModal] = useState(false);

	const layoutContext = useLayoutContext();

	const {isLoggedIn} = useSelector(state => state.AuthReducer);
	const location = useLocation();
	const dispatch = useDispatch();
	const {pathname} = location;

	const handleLogout = () => {
		dispatch(logout());
		window.location.href = "/auth/login";
	};
	const splitLocation = pathname.split("/");

	const appsMenu = getAppsMenu(isLoggedIn);

	return (
		<>
			<ModalConfirmation
				approveLabel="Ya"
				description="Apakah anda yakin ingin keluar dari aplikasi?"
				rejectLabel="Batal"
				title="Konfirmasi Keluar"
				visible={showLogoutModal}
				onApprove={handleLogout}
				onClose={() => setShowLogoutModal(false)}
				onReject={() => setShowLogoutModal(false)}
			/>

			<div className="sidebar-content">
				<div className="sc-top">
					{!isLoggedIn ? (
						<div className="location mb-2">
							<div className="loc-top">
								<div className="text-green ft-13">Sudah memiliki akun?</div>
							</div>
							<a className="link-loc change-loc cursor-pointer" href="/">
								<span className="icon-ico-logout" />
								<div className="side-desc">Login</div>
							</a>
						</div>
					) : null}

					{appsMenu?.top?.map(menu => {
						if (menu.submenu?.length)
							return (
								<div key={menu.id}>
									<button
										className={clsx(
											"link-side ",
											splitLocation[1] === "" &&
												pathname === "/" &&
												menu.path === "/" &&
												"active",
										)}
										style={menuWithSubmenuStyles}
										onClick={() => {
											layoutContext.onSetSelectedSubmenu(menu.id);
											layoutContext.onToggleSubmenu();
										}}>
										<span className={menu.icon} />
										<div className="side-desc">
											{menu.title}
											{layoutContext.showSubmenu &&
												layoutContext.selectedShowSubmenu === menu.id && (
													<span style={{position: "absolute", right: 16, marginTop: -4}}>
														&#9650;
													</span>
												)}
											{!(
												layoutContext.showSubmenu &&
												layoutContext.selectedShowSubmenu === menu.id
											) && <span style={{position: "absolute", right: 16}}>&#9660;</span>}
										</div>
									</button>

									{layoutContext.showSubmenu && layoutContext.selectedShowSubmenu === menu.id && (
										<ul>
											{menu.submenu.map(e => {
												return (
													<Link
														key={e.id}
														className={clsx(
															"link-side ",
															splitLocation[1] === "" &&
																pathname === "/" &&
																e.path === "/" &&
																"active",
															pathname !== "/" &&
																splitLocation.includes(checkCurrentPath(e.path)) &&
																"active",

															pathname !== "/" && e.id === pathname && "active",
														)}
														to={e.path}>
														<span className={e.icon} />
														<div className="side-desc">{e.title}</div>
													</Link>
												);
											})}
										</ul>
									)}
								</div>
							);

						return (
							<Link
								key={menu.id}
								className={clsx(
									"link-side ",
									splitLocation[1] === "" && pathname === "/" && menu.path === "/" && "active",
									pathname !== "/" && splitLocation.includes(checkCurrentPath(menu.path)) && "active",
									menu.path === pathname && "active",
								)}
								to={menu.path}>
								<span className={menu.icon} />
								<div className="side-desc">{menu.title}</div>
							</Link>
						);
					})}
				</div>

				<div className="sc-bottom">
					{appsMenu?.bottom?.map(menu => (
						<Link
							key={menu.id}
							className={clsx(
								"link-side ",
								splitLocation[1] === "" && pathname === "/" && menu.path === "/" && "active",
								pathname !== "/" && splitLocation[1].includes(checkCurrentPath(menu.path)) && "active",
								menu.path === pathname && "active",
							)}
							to={menu.path}>
							<span className={menu.icon} />
							<div className="side-desc">{menu.title}</div>
						</Link>
					))}
					{isLoggedIn ? (
						<a className="link-side" href="#" onClick={() => setShowLogoutModal(true)}>
							<span className="icon-ico-logout" />
							<div className="side-desc">Logout</div>
						</a>
					) : null}
				</div>
			</div>
		</>
	);
}
