import {DashboardLayout} from "components/Layout";
import * as Yup from "yup";
import {Formik} from "formik";
import {useRef} from "react";
import {useAddLayananObat, useEditLayananObat} from "hooks/LayananObat/useMutationLayananObat";
import {convertErrorMessageFormat} from "utils/converter";
import {useToast} from "hooks/useToast";
import {useHistory, useParams} from "react-router-dom";
import {useDetailLayanan} from "hooks/LayananObat";

import {LayananInformasiObat} from "./components/InformasiObatForm";

const FormSchemaValidation = Yup.object().shape({
	hospitalId: Yup.string().required("Rumah Sakit wajib di isi"),
	divisionId: Yup.string().required("Divisi wajib di isi"),
	categoryId: Yup.string().required("Kategori Obat wajib di isi"),
	medicineId: Yup.string().required("Nama Obat wajib di isi"),
	details: Yup.array().of(
		Yup.object().shape({
			unitId: Yup.string(),
			sediaanId: Yup.string(),
			qty: Yup.number(),
			price: Yup.number(),
		}),
	),
	description: Yup.string(),
	createdBy: Yup.string(),
});

const FormInitialValues = {
	hospitalId: "",
	description: "",
	divisionId: "",
	categoryId: "",
	medicineId: "",
	details: [],
	createdBy: "vendor",
};

const LayananObatDetail = () => {
	const {id, action} = useParams();
	const {showToast} = useToast();
	const history = useHistory();
	const formikRef = useRef();
	const {data} = useDetailLayanan(id);
	const {mutate: queryAdd} = useAddLayananObat();
	const {mutate: queryEdit} = useEditLayananObat();

	const handleSubmit = data => {
		if (action === "tambah") {
			queryAdd(data, {
				onSuccess: () => {
					showToast("success", "Data berhasil ditambahkan", 3000);
					history.push("/layanan-obat");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			});
		} else {
			queryEdit(
				{id, ...data},
				{
					onSuccess: () => {
						showToast("success", "Data berhasil diperbarui", 3000);
						history.push("/layanan-obat");
					},
					onError: res => {
						showToast(
							"error",
							convertErrorMessageFormat(res.response.status, res.response.data.message),
							null,
						);
					},
				},
			);
		}
	};

	return (
		<DashboardLayout>
			<div class="box-white setting-content h-100 no-border p24">
				<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
					<div class="ttl-20 text-capitalize">{action} Layanan Obat</div>
				</div>

				<div class="text-bold mb-4">
					<a class="text-dark cursor-pointer" onClick={() => history.push("/layanan-obat")}>
						<span class="icon-ico-back me-2" />
					</a>
					Daftar List Layanan Obat
				</div>

				<hr />
				<Formik
					initialValues={FormInitialValues}
					innerRef={formikRef}
					validationSchema={FormSchemaValidation}
					onSubmit={handleSubmit}>
					{({handleSubmit, values, errors, touched, setFieldValue}) => (
						<>
							{values && FormSchemaValidation ? (
								<LayananInformasiObat
									FormSchemaValidation={FormSchemaValidation}
									action={action}
									data={data}
									errors={errors}
									handleSubmit={handleSubmit}
									setFieldValue={setFieldValue}
									touched={touched}
									values={values}
								/>
							) : null}
						</>
					)}
				</Formik>
			</div>
		</DashboardLayout>
	);
};

export {LayananObatDetail};
