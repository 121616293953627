import {HomePage} from "pages/Home";

export const homeRoutes = [
	{
		name: "admin-home",
		path: "/",
		exact: true,
		isPrivate: true,
		component: HomePage,
	},
];
