import {useMemo, useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

function useSediaanOptions() {
	const result = useQuery(["sediaanOptions"], async () => await axios.get(`/sediaan`).then(res => res.data));

	return result;
}

function useMedicineUnitOptions() {
	const result = useQuery(
		["medicineUnitOptions"],
		async () => await axios.get(`/medicine/unit`).then(res => res.data),
	);

	return result;
}

function useHospitals(enabled = true, limit = 10) {
	const [filter, filterHospitals] = useState({
		page: 1,
		search: null,
		limit,
		sortBy: "id.desc",
	});
	const [hospitalId, hospitalById] = useState(null);
	const fallback = [];
	const {
		data: hospitals = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(
		["hospitals", hospitalId, filter],
		async () => await axios.get(`/hospital`, {params: {...filter, sortBy: "id.desc"}}),
		{enabled},
	);

	return {hospitals, isLoading, isError, error, filter, filterHospitals, hospitalById};
}

function useHospitalsOptions(enabled) {
	const {hospitals, isLoading} = useHospitals(enabled);
	const hospitalOptions = useMemo(() => {
		if (hospitals?.data?.success) {
			return hospitals?.data?.data?.rows?.map(hospital => ({
				value: hospital?.id + "",
				label: hospital?.name,
				desc: hospital?.address,
				image: hospital?.logo,
			}));
		}

		return [];
	}, [hospitals]);

	return {hospitalOptions, isLoading};
}

async function getMedicines(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/medicine`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/medicine/${id}`);

	return data;
}

function useListAvailableMedicine(hospitalId, initialFilters, queryOptions) {
	const [filter, filterMedicines] = useState({
		page: 1,
		search: null,
		limit: 10,
		hospitalId,
		...initialFilters,
	});

	const [medicineId, medicineById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(
		["medicines", {...filter, ...initialFilters, hospitalId}, medicineId, initialFilters.medicineCategoryId],
		async () => getMedicines(medicineId, {...filter, ...initialFilters, hospitalId}),
		{
			...queryOptions,
		},
	);

	return {data, isLoading, isError, error, filter, filterMedicines, medicineById};
}

function useDetailMedicine(medicineId, enabled = true) {
	return useQuery({
		queryKey: medicineId && ["medicine", medicineId],
		queryFn: () => axios.get(`/v2/medicine/${medicineId}`).then(res => res.data),
		refetchOnMount: true,
		refetchOnWindowFocus: false,
		enabled: enabled && !!medicineId,
	});
}

function useListDivisionById(filter, enabled) {
	return useQuery({
		queryKey: ["rs_division", filter],
		queryFn: () => axios.get(`division/?sortBy=id.desc&${qs.stringify(filter)}`).then(res => res.data),
		refetchOnMount: false,
		enabled: enabled && !!Object.keys(filter).length && !!filter.hospitalId,
	});
}

function useMedicineCategoryOptions({hospitalId}, enabled = true) {
	const result = useQuery(
		["medicineCategoryOptionsHospital", hospitalId],
		async () =>
			await axios
				.get(`/medicine/category`, {params: {hospitalId, isActive: true, sortBy: "id.desc"}})
				.then(res => res.data),
		{
			enabled: enabled && !!hospitalId,
		},
	);

	return result;
}

export {
	useSediaanOptions,
	useMedicineUnitOptions,
	useHospitalsOptions,
	useListAvailableMedicine,
	useListDivisionById,
	useMedicineCategoryOptions,
	useDetailMedicine,
};
