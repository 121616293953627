import {LayananObatDetail, LayananObatList, LayananObatSubmit} from "pages/LayananObat";

export const layananObatRoutes = [
	{
		name: "layanan-obat-submit",
		path: "/layanan-obat/submit",
		exact: true,
		isPrivate: false,
		component: LayananObatSubmit,
	},
	{
		name: "layanan-obat",
		path: "/layanan-obat",
		exact: true,
		isPrivate: true,
		component: LayananObatList,
	},
	{
		name: "layanan-obat-tambah",
		path: "/layanan-obat/:action",
		exact: true,
		isPrivate: true,
		component: LayananObatDetail,
	},
	{
		name: "layanan-obat-edit",
		path: "/layanan-obat/:action/:id",
		exact: true,
		isPrivate: true,
		component: LayananObatDetail,
	},
];
