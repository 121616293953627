import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getLayananObat(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/v2/vendor/medicine`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/v2/vendor/medicine/${id}`);

	return data;
}

function useLayananObat() {
	const [filter, filterLayanan] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [layananId, layananById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["medicine_service", filter, layananId], async () => getLayananObat(layananId, filter));

	return {data, isLoading, isError, error, filter, filterLayanan, layananById};
}

const fetchLayanan = layananId => {
	return axios.get(`/v2/vendor/medicine/${layananId}`).then(res => res.data);
};

function useDetailLayanan(layananId) {
	return useQuery({
		queryKey: layananId && ["medicine_service", layananId],
		queryFn: () => fetchLayanan(layananId),
		refetchOnMount: true,
		refetchOnWindowFocus: false,
		enabled: !!layananId,
	});
}

export {useLayananObat, useDetailLayanan};
