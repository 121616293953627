import {useSelector} from "react-redux";
import {loadLocalAssets} from "utils/loader";

import {ModalContainer} from "./ModalContainer";

export function ModalProfile({visible, onClose, title}) {
	const userProfile = useSelector(state => state.AuthReducer.profile);

	return (
		<ModalContainer visible={visible}>
			<div class="box-popup w550" id="withdraw">
				<div class="d-flex justify-content-between align-items-center mb-4">
					<div class="text-bold text-dark">{title}</div>
					<div class="for-close">
						<button class="btn btn-close" type="button" onClick={onClose} />
					</div>
				</div>
				<div class="popup-content pb-2">
					<div class="d-flex align-items-center">
						<div class="img-dp me-3">
							<img
								alt="profile"
								class="img-fluid"
								src={userProfile?.profilePhoto || loadLocalAssets("img/default_avatar.png")}
							/>
						</div>
						<div class="desc-dp">
							<div class="name-dp">
								{userProfile?.firstName} {userProfile?.lastName}
							</div>
						</div>
					</div>
					<hr />
					<div class="row">
						<div class="col-6">
							<div class="ft-12 text-grey">Email</div>
							<p>{userProfile?.email}</p>
						</div>
						<div class="col-6">
							<div class="ft-12 text-grey">Phone number</div>
							<p>+{userProfile?.phoneNumber}</p>
						</div>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}
