export const getAppsMenu = isLoggedIn => {
	if (isLoggedIn) {
		return {
			top: [
				{
					id: "layanan-obat",
					title: "Layanan Obat",
					icon: "icon-aid-kit",
					path: "/layanan-obat",
					submenu: [],
				},
			],
			bottom: [],
		};
	}

	return {top: [], bottom: []};
};
