import {Switch} from "react-router-dom";

import Route from "./RouteWrapper";
import {authenticationRoutes} from "./Auth";
import {homeRoutes} from "./Home";
import {layananObatRoutes} from "./LayananObat";

const AllRoutes = [...authenticationRoutes, ...homeRoutes, ...layananObatRoutes];

export default function AppRoutes() {
	return (
		<Switch>
			{AllRoutes.map(route => {
				return (
					<Route
						key={route.name}
						component={route.component}
						exact={route.exact}
						isPrivate={route.isPrivate}
						path={route.path}
					/>
				);
			})}
		</Switch>
	);
}
