import Cleave from "cleave.js/react";
import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {BasicDropdown} from "components/Dropdown";
import {FieldArray} from "formik";
import {
	useNoAuthDetailMedicine,
	useNoAuthHospitalsOptions,
	useNoAuthListAvailableMedicine,
	useNoAuthListDivisionById,
	useNoAuthMedicineCategoryOptions,
	useNoAuthMedicineUnitOptions,
	useNoAuthSediaanOptions,
} from "hooks/options-no-auth";
import {useEffect, useMemo, useState} from "react";
import {Col, Row} from "react-bootstrap";

const EMPTY_FUNC = () => {};
let isDirty = false;

const NoAuthLayananInformasiObat = ({
	action,
	data = null,
	handleSubmit = EMPTY_FUNC,
	FormSchemaValidation,
	values,
	errors,
	touched,
	setFieldValue,
}) => {
	const {hospitalOptions, isLoading: isLoadingHospitalOptions} = useNoAuthHospitalsOptions(action !== "detail");
	const {data: divisions, isLoading: isLoadingDivisions} = useNoAuthListDivisionById(
		{
			hospitalId: values?.hospitalId,
			isActive: true,
		},
		action !== "detail",
	);
	const [divisionOptions, setDivisionOptions] = useState([]);
	const {data: medicineCategory, isLoading: isLoadingMedicineCategory} = useNoAuthMedicineCategoryOptions(
		{
			hospitalId: values?.hospitalId,
		},
		action !== "detail",
	);
	const [medicineCategoryOptions, setMedicineCategoryOptions] = useState([]);

	const [medicineOptions, setMedicineOptions] = useState([]);
	const {data: medicineQuery, isLoading: medicineQueryLoading} = useNoAuthListAvailableMedicine(
		values?.hospitalId,
		values?.categoryId,
	);
	const {data: unitQuery} = useNoAuthMedicineUnitOptions();
	const {data: sediaanQuery} = useNoAuthSediaanOptions();
	const unitOptions = useMemo(() => {
		if (!unitQuery?.success) return [];

		const list = unitQuery?.data?.map(o => ({
			value: o?.id,
			label: `${o?.name}`,
		}));

		return list;
	}, [unitQuery]);

	const sediaanOptions = useMemo(() => {
		if (!sediaanQuery?.success) return [];

		const list = sediaanQuery?.data?.map(o => ({
			value: o?.id,
			label: `${o?.name}`,
		}));

		return list;
	}, [sediaanQuery]);

	const {data: detailMedicine} = useNoAuthDetailMedicine(values?.medicineId, action !== "detail");

	useEffect(() => {
		if (divisions?.success) {
			const list = divisions?.data?.map(d => ({
				value: d?.id + "",
				label: `${d?.name}`,
			}));

			setDivisionOptions([...list]);
		}
	}, [divisions]);

	useEffect(() => {
		if (medicineCategory?.success) {
			const list = medicineCategory?.data?.map(d => ({
				value: d?.id + "",
				label: `${d?.name}`,
			}));

			setMedicineCategoryOptions([...list]);
		}
	}, [medicineCategory]);

	useEffect(() => {
		if (values?.categoryId && medicineQuery?.success) {
			const list = medicineQuery.data
				?.filter(e => e.category.id === parseInt(values?.categoryId))
				.map(e => ({label: e.name, value: e.id}));

			setMedicineOptions(list);
		}
	}, [medicineQuery, values?.categoryId]);

	useEffect(() => {
		if (detailMedicine?.success && isDirty) {
			for (let i = 0; i < detailMedicine?.data?.sediaan?.length; i++) {
				const sediaanId = detailMedicine?.data?.sediaan?.[i]?.sediaanId;
				const unitId = detailMedicine?.data?.unitV2?.[i]?.unitId;

				setFieldValue(`details.${i}.sediaanId`, sediaanId);
				setFieldValue(`details.${i}.unitId`, unitId);
				setFieldValue(`details.${i}.qty`, 0);
				setFieldValue(`details.${i}.price`, 0);
			}
		}
	}, [detailMedicine]);

	useEffect(() => {
		if (data?.success) {
			setFieldValue("hospitalId", data?.data?.hospitalId);
			setFieldValue("divisionId", data?.data?.divisionId);
			setFieldValue("categoryId", data?.data?.categoryId);
			setFieldValue("medicineId", data?.data?.medicineId);
			setFieldValue("description", data?.data?.description);

			for (let i = 0; i < data?.data?.vendorMedicine?.length; i++) {
				const vendorMedicine = data?.data?.vendorMedicine?.[i];

				setFieldValue(`details.${i}`, {
					sediaanId: vendorMedicine?.sediaanId,
					unitId: vendorMedicine?.unitId,
					qty: vendorMedicine?.qty,
					price: vendorMedicine?.price,
				});
			}
		}

		return () => {
			isDirty = false;
		};
	}, [data]);

	return (
		<>
			<div class="text-bold text-black text-decoration-underline mb-4">Informasi Obat</div>

			<div class="row gx-2 mb-3">
				<div class="col-sm-6 col-6">
					<div class="setting-item">
						<label class="setting-label required">Rumah Sakit</label>
						{action === "detail" ? (
							<div className="text-bold text-grey">{data?.data?.hospital?.name}</div>
						) : (
							<>
								<BasicDropdown
									className={clsx("form-control", errors?.hospitalId && "form-control-error")}
									data={hospitalOptions ? [{label: "", value: ""}, ...hospitalOptions] : []}
									disabled={isLoadingHospitalOptions}
									name="hospitalId"
									value={values?.hospitalId}
									onSelect={fieldValue => {
										setFieldValue("hospitalId", fieldValue?.toString());
										setFieldValue("categoryId", "");
										setFieldValue("medicineId", "");
										setFieldValue("details", []);
										setMedicineOptions([]);
										isDirty = true;
									}}
								/>
								{errors?.hospitalId && touched?.hospitalId && (
									<p className="form-error-item-message">{errors.hospitalId}</p>
								)}
							</>
						)}
					</div>
				</div>
			</div>

			<div class="row gx-2 mb-3">
				<div class="col-sm-6 col-6">
					<div class="setting-item">
						<label class="setting-label required">Divisi</label>
						{action === "detail" ? (
							<div className="text-bold text-grey">{data?.data?.division?.name}</div>
						) : (
							<>
								<BasicDropdown
									className={clsx("form-control", errors?.divisionId && "form-control-error")}
									data={[{label: "", value: ""}, ...divisionOptions] || []}
									disabled={isLoadingDivisions}
									name="divisionId"
									value={values?.divisionId}
									onSelect={fieldValue => {
										setFieldValue("divisionId", fieldValue);
										isDirty = true;
									}}
								/>
								{errors?.divisionId && touched?.divisionId && (
									<p className="form-error-item-message">{errors.divisionId}</p>
								)}
							</>
						)}
					</div>
				</div>
			</div>

			<div class="row gx-2 mb-3">
				<div class="col-sm-6 col-6">
					<div class="setting-item">
						<label class="setting-label required">Kategori Obat</label>
						{action === "detail" ? (
							<div className="text-bold text-grey">{data?.data?.category?.name}</div>
						) : (
							<>
								<BasicDropdown
									className={clsx("form-control", errors?.categoryId && "form-control-error")}
									data={[{label: "", value: ""}, ...medicineCategoryOptions] || []}
									disabled={isLoadingMedicineCategory}
									name="categoryId"
									value={values?.categoryId}
									onSelect={fieldValue => {
										setFieldValue("categoryId", fieldValue);
										setFieldValue("medicineId", "");
										setFieldValue("details", []);
										isDirty = true;
									}}
								/>
								{errors?.categoryId && touched?.categoryId && (
									<p className="form-error-item-message">{errors.categoryId}</p>
								)}
							</>
						)}
					</div>
				</div>
			</div>

			<div class="row gx-2 mb-3">
				<div class="col-sm-6 col-6">
					<div class="setting-item">
						<label class="setting-label required">Nama Obat</label>
						{action === "detail" ? (
							<div className="text-bold text-grey">{data?.data?.medicine?.name}</div>
						) : (
							<>
								<BasicDropdown
									className={clsx("form-control", errors?.medicineId && "form-control-error")}
									data={[{label: "", value: ""}, ...medicineOptions] || []}
									disabled={medicineQueryLoading}
									name="medicineId"
									value={values?.medicineId}
									onSelect={fieldValue => {
										setFieldValue("medicineId", fieldValue);
										setFieldValue("details", []);
										isDirty = true;
									}}
								/>
								{errors?.medicineId && touched?.medicineId && (
									<p className="form-error-item-message">{errors.medicineId}</p>
								)}
							</>
						)}
					</div>
				</div>
			</div>

			<FieldArray name="details">
				{({remove, push}) => (
					<>
						{values.details?.map((o, idx) => {
							return (
								<Row key={`details-${idx}`} className="gx-2 mb-3">
									<Col>
										<div class="setting-item">
											{idx === 0 ? <label class="setting-label required">Satuan</label> : null}
											{action === "detail" ? (
												<div className="text-bold text-grey">
													{sediaanOptions?.find(x => x.value === o.sediaanId)?.label}
												</div>
											) : (
												<>
													<BasicDropdown
														className={clsx(
															"form-control",
															errors?.details?.[idx]?.sediaanId && "form-control-error",
														)}
														data={sediaanOptions}
														name={`details.${idx}.sediaanId`}
														value={o.sediaanId}
														onSelect={fieldValue => {
															setFieldValue(
																`details.${idx}.sediaanId`,
																Number(fieldValue),
															);
															isDirty = true;
														}}
													/>
													{errors?.details?.[idx]?.sediaanId &&
														touched?.details?.[idx]?.sediaanId && (
															<p className="form-error-item-message">
																{errors?.details?.[idx]?.sediaanId}
															</p>
														)}
												</>
											)}
										</div>
									</Col>
									<Col>
										<div class="setting-item">
											{idx === 0 ? <label class="setting-label required">Unit</label> : null}
											{action === "detail" ? (
												<div className="text-bold text-grey">
													{unitOptions?.find(x => x.value === o.unitId)?.label}
												</div>
											) : (
												<>
													<BasicDropdown
														className={clsx(
															"form-control",
															errors?.details?.[idx]?.unitId && "form-control-error",
														)}
														data={unitOptions}
														name={`details.${idx}.unitId`}
														value={o.unitId}
														onSelect={fieldValue => {
															setFieldValue(`details.${idx}.unitId`, Number(fieldValue));
															isDirty = true;
														}}
													/>
													{errors?.details?.[idx]?.unitId &&
														touched?.details?.[idx]?.unitId && (
															<p className="form-error-item-message">
																{errors?.details?.[idx]?.unitId}
															</p>
														)}
												</>
											)}
										</div>
									</Col>
									<Col>
										<div class="setting-item">
											{idx === 0 ? <label class="setting-label required">Jumlah</label> : null}
											{action === "detail" ? (
												<div className="text-bold text-grey">{o.qty}</div>
											) : (
												<>
													<Cleave
														className={clsx(
															"form-control",
															errors?.details?.[idx]?.qty && "form-control-error",
														)}
														name={`details.${idx}.qty`}
														options={{
															numeral: true,
															numeralThousandsGroupStyle: "thousand",
														}}
														value={o.qty}
														onChange={e => {
															setFieldValue(`details.${idx}.qty`, e.target.rawValue);
															isDirty = true;
														}}
													/>
													{errors?.details?.[idx]?.qty && touched?.details?.[idx]?.qty && (
														<p className="form-error-item-message">
															{errors?.details?.[idx]?.qty}
														</p>
													)}
												</>
											)}
										</div>
									</Col>
									<Col>
										<div class="setting-item">
											{idx === 0 ? (
												<label class="setting-label required">Harga Satuan</label>
											) : null}
											{action === "detail" ? (
												<div className="text-bold text-grey">
													Rp. {o.price.toLocaleString("id-ID")}
												</div>
											) : (
												<>
													<Cleave
														className={clsx(
															"form-control",
															errors?.details?.[idx]?.price && "form-control-error",
														)}
														name={`details.${idx}.price`}
														options={{
															numeral: true,
															numeralThousandsGroupStyle: "thousand",
															prefix: "Rp ",
															rawValueTrimPrefix: true,
														}}
														value={o.price}
														onChange={e => {
															setFieldValue(`details.${idx}.price`, e.target.rawValue);
															isDirty = true;
														}}
													/>
													{errors?.details?.[idx]?.price &&
														touched?.details?.[idx]?.price && (
															<p className="form-error-item-message">
																{errors?.details?.[idx]?.price}
															</p>
														)}
												</>
											)}
										</div>
									</Col>
									<Col>
										<div class="setting-item">
											{idx === 0 ? <label class="setting-label">Total Harga</label> : null}
											{action === "detail" ? (
												<div className="text-bold text-grey">
													Rp. {(o.price * o.qty).toLocaleString("id-ID")}
												</div>
											) : (
												<>
													<Cleave
														className="form-control"
														options={{
															numeral: true,
															numeralThousandsGroupStyle: "thousand",
															prefix: "Rp ",
															rawValueTrimPrefix: true,
														}}
														readOnly={true}
														value={o.price * o.qty ? o.price * o.qty : 0}
													/>
												</>
											)}
										</div>
									</Col>
									{action !== "detail" ? (
										<Col className="d-flex align-items-end" xs={1}>
											<div className="d-flex gap-2 mb-4">
												{idx === values?.details.length - 1 ? (
													<a
														class={clsx("text-orange wait-pay")}
														style={{cursor: "pointer"}}
														onClick={() => {
															push({
																unitId: unitOptions[0].value,
																sediaanId: sediaanOptions[0].value,
																qty: 0,
																price: 0,
															});
															isDirty = true;
														}}>
														<span class="icon-ico-plus ft-18" />
													</a>
												) : null}
												{values?.details.length > 1 ? (
													<a
														class={clsx("text-orange wait-pay")}
														style={{cursor: "pointer"}}
														onClick={() => {
															remove(idx);
															isDirty = true;
														}}>
														<span class="icon-ico-delete ft-18" />
													</a>
												) : null}
											</div>
										</Col>
									) : null}
								</Row>
							);
						})}
					</>
				)}
			</FieldArray>

			<div class="row gx-2 mb-3">
				<div class="col-sm-6 col-6">
					<div class="setting-item">
						<label class="setting-label">Deskripsi</label>
						{action === "detail" ? (
							<div className="text-bold text-grey">{data?.data?.description}</div>
						) : (
							<>
								<textarea
									className={clsx("form-control", errors?.description && "form-control-error")}
									name="description"
									rows={5}
									value={values?.description}
									onChange={e => {
										setFieldValue("description", e.target.value);
									}}
								/>
								{errors?.description && touched?.description && (
									<p className="form-error-item-message">{errors.description}</p>
								)}
							</>
						)}
					</div>
				</div>
			</div>

			{action !== "detail" ? (
				<div class="text-center my-3">
					<SubmitButton
						className="btn btn-orange d-inline-block mw-250"
						disabled={!FormSchemaValidation.isValidSync(values)}
						text="Simpan"
						onClick={handleSubmit}
					/>
				</div>
			) : null}
		</>
	);
};

export {NoAuthLayananInformasiObat};
